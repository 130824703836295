<template>
  <div class="bg-white">
    <app-modal-confirm
      ref="menu_delete_modal"
      :modal-header-title="$t('common.delete confirm title')"
      @confirmed="deleteMenu()"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("common.delete confirm") }}</p>
      </template>
    </app-modal-confirm>

    <app-modal-confirm
      ref="plan_tax"
      :modal-header-title="$t('announcements.confirm_title')"
      @cancel="handlerTax"
      @confirmed="closeModalTax"
    >
      <template v-slot:body>
        <p class="confirm-body">価格の{{rateTax}}%を超える金額が消費税額として入力されています。このまま設定してもよろしいですか？</p>
      </template>
    </app-modal-confirm>

    <validation-observer
      ref="entry"
      v-slot="{ passes, valid, validated }"
    >

      <form class="form-horizontal">
        <div class="card-body">
          <div v-if="idEditing">
            <div class="form-group row">
              <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common.id") }}</label>
              <div class="col-xl-5 col-lg-6 col-sm-6 p-t-8"> {{ entry.id }}</div>
            </div>

          </div>
          <validation-provider
            name="status_enabled"
            rules="required"
            v-slot="{ errors, invalid, validated }"
          >
            <div class="form-group row">
              <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("menu.status")}} <span
                class="app-require">＊</span>
              </label>
              <div class="col-xl-5 col-lg-6 col-sm-9 align-self-center">
                <app-radio v-for="(item, index) in MENU_CONSTANT.STATUS"
                           :isMarginLeft="index !=0 ? true : false" :key="item.id" :label="item.name"
                           :val="item.id" v-model="entry.status_enabled"/>
                <label v-if="invalid && validated" class="error">
                  {{ errors[0] }}
                </label>
              </div>
            </div>
          </validation-provider>
          <div class="form-group row">
            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("menu.mansion") }}
              <span
                class="app-require">＊</span>
            </label>
            <div class="col-xl-5 col-lg-9 col-sm-9">
              <app-select
                name="apartment_id"
                rules="required"
                :options-data="meta.apartments"
                v-model="entry.apartment_id"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label num-people">{{ $t("menu.name") }}
              <span
                class="app-require">＊</span>
            </label>
            <div class="col-xl-5 col-lg-9 col-sm-9 num-people">
              <app-input
                name="name"
                rules="required"
                :class="['flex-fill']"
                v-model="entry.name"
                type="string:128"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label num-people">{{ $t("menu.price") }}

            </label>
            <div class="col-xl-5 col-lg-9 col-sm-9 num-people">
              <app-input
                name="plan_price"
                type="number-length:11"
                @focusOutEvent="focusOutEvent"
                :class="['flex-fill']"
                v-model="entry.price"
                :rules="VALIDATE.V_PLAN_PRICE"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label num-people">{{ $t("menu.tax") }}
            </label>
            <div class="col-xl-5 col-lg-9 col-sm-9 num-people">
              <app-input
                name="plan_tax"
                type="number-length:11"
                @focusOutEvent="focusOutEvent"
                :class="['flex-fill']"
                v-model="entry.tax"
                :rules="VALIDATE.V_PLAN_PRICE"
              />
            </div>
          </div>
          <validation-provider
            name="is_reduced_tax"
            v-slot="{ errors, invalid, validated }"
          >
            <div class="form-group row">
              <label class="col-xl-2 col-lg-3 col-sm-2 col-form-label">{{ $t("menu.is_reduced_tax") }}
              </label>
              <div class="col-xl-5 col-lg-6 col-sm-7 gr-icheck">
                <app-check-box
                  :label="$t('plans.text_is_reduced_tax')"
                  v-model="entry.is_reduced_tax"
                  class="m-r-20"
                  val="1"
                />
                <label v-if="invalid && validated" class="error">
                  {{ errors[0] }}
                </label>
              </div>
            </div>
          </validation-provider>
          <div class="form-group row">
            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("menu.category") }}

            </label>
            <div class="col-xl-5 col-lg-9 col-sm-9">
              <app-select
                name="category_id"
                :options-data="meta.categories"
                v-model="entry.category_id"
              />
            </div>
          </div>
          <validation-provider
            name="image_path"
            ref="file_preview"
            v-slot="{ errors, invalid, validated }"
          >
            <div class="form-group row">
              <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("menu.image_path") }}

              </label>
              <div class="col-xl-5 col-lg-6 col-sm-9">
                <app-single-file-input accept="image/jpeg, image/png" name="image_path"
                                       :imageSrc="entry.image_path"  v-model="entry.image"
                                       @input="handlerUploadImage" @updated_at="entry.data_updated_at = $event"  :allowedExtension="allowedExtension" :delete-btn="true" :url-remove="ENDPOINT.MENU_DELETE_IMAGE(idEditing)" :id-editing="idEditing"></app-single-file-input>
              </div>
            </div>
          </validation-provider>
          <div class="form-group row">
            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("service_category.note") }}
            </label>
            <div class="col-xl-5 col-lg-9 col-sm-9">
              <app-text
                name="note"
                type="string1024"
                :class="['flex-fill']"
                v-model="entry.note"
              />
            </div>
          </div>
          <div class="card-footer row justify-content-end gr-button">
            <button type="button" :disabled="!valid && validated" class="btn btn-default m-r-10"
                    @click="handlerCancelButton">
              {{$t('common.cancel') }}
            </button>
            <button type="button" class="btn btn btn-danger m-r-10" :disabled="!valid && validated" @click="handleOpenCancelBookModal" v-if="idEditing">
              {{ $t('common.delete') }}
            </button>
            <button class="btn btn-primary" :disabled="!valid && validated" type="button" @click="createOrUpdate">
              {{idEditing ? $t('common.save') : $t('common.create')}}
            </button>
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>

  import {FACILITIES_CONSTANT} from "../../../constants/facilities";
  import {RESERVATION_CONSTANT} from "../../../constants/reservation";
  import {ROUTES} from "@constants";
  import {RATE_TAX} from "@constants/api";


  export default {
    name: "MenuCreateOrEdit",

    data() {
      const id = this.$route.params.id
      return {
        meta: {
          apartments: [],
          categories : []
        },
        entry: {
          apartment_id: "",
          category_id : "",
          status_enabled : 1,
          is_reduced_tax : ["1"]
        },
        idEditing: id,
        loading: true,
        allowedExtension: ['image/jpeg', 'image/png'],
        rateTax: RATE_TAX
      }
    },
    watch: {
      "entry.apartment_id": async function (value) {
        if (value) {
          await this.getMetaData({apartment_id: value})
        } else {
          this.meta.categories = [{
            id: "",
            name: "--選択--"
          }]
        }
      },
    },
    async created() {
      if (this.idEditing) {
        this.loading = false
        await this.$request.get(this.ENDPOINT.MENU_EDIT(this.idEditing)).then(res => {
          if (res.hasErrors()) {
            if (res.status === 'error'){
              this.__noticeError(this.$t(res.data.error_msg));
            }
            this.redirectDefaultValue()
          } else {
            this.entry = res.data.data
          }
        })
      }
    },

    mounted: async function () {
      $(".tooltip").tooltip("hide");
      if (!this.idEditing) {
        await this.getMetaData({});
      }
    },
    methods: {
      handlerUploadImage(data) {
        this.entry.image = data
        this.entry.image_path = data
      },
      async getMetaData(params) {
        this.$request.get(this.ENDPOINT.UI_HELPER_MENU, params).then(res => {
          this.meta = {
            apartments: res.data.apartments,
            categories : res.data.categories
          }
          this.meta.apartments.unshift({
            id: "",
            name: "--選択--"
          })
          this.meta.categories.unshift({
            id: "",
            name: "--選択--"
          })
        })
      },
      closeModalTax() {
        this.$refs.plan_tax.hide();
      },
      focusOutEvent(){
        if ( this.entry.tax && this.entry.price&& ((this.entry.tax / this.entry.price) * 100) > RATE_TAX ) {
          this.$refs.plan_tax.show();
        }
      },
      handlerTax(){
        this.entry.tax = '';
      },

      handleOpenCancelBookModal() {
        this.$refs.menu_delete_modal.show();
      },
      async createOrUpdate() {
        if (!await this.$refs.entry.validate()) {
          return;
        }
        let res = null;
        let msg = "";

        if (this.idEditing) {
          res = await this.$request.patch(this.ENDPOINT.MENU_EDIT(this.idEditing), this.entry);
          msg = this.$t("common.msg update ok")
        } else {
          res = await this.$request.post(this.ENDPOINT.MENU_CREATE, this.entry);
          msg = this.$t("common.msg create ok")
        }
        if (res.hasErrors()) {
          this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
          if (res.status === 'error') {
            this.redirectDefaultValue()
          }
        } else {
          this.__noticeSuccess(msg);
          this.redirectDefaultValue();
        }
      },
      async deleteMenu() {
        const res = await this.$request.delete(this.ENDPOINT.MENU_DELETE(this.idEditing));
        if (!res.hasErrors()) {
          this.__noticeSuccess(this.$t("common.msg delete ok"));
          this.redirectDefaultValue();
        } else {
          this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
          this.$refs.menu_delete_modal.hide();
          if (res.status === 'error') {
            this.redirectDefaultValue()
          }
        }
      },
      handlerCancelButton() {
        if(this.$store.state.setting.prevRouter.name === ROUTES.ADMIN.MENU_LIST) {
          this.__redirectBack()
        } else {
          this.redirectDefaultValue()
        }
      },
      redirectDefaultValue() {
        this.$router.push({
          name: this.ROUTES.ADMIN.MENU_LIST,
          query: {
            'filters.apartment_id.equal' : 'all',
            'filters.category_id.equal' : 'all',
            'filters.status_enabled.equal' : 'all'
          }
        });
      },
    }
  }
</script>

<style>
  @media (min-width: 768px) and (max-width: 1400px) {
    .icon_nga {
      width: 100%;
    }
    .num-people {
      padding-top: 20px;
    }
  }
  .link_name:hover {
    cursor: pointer;
    color: darkblue !important;
  }
</style>
